import { environment } from 'src/environments/environment';
import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  PathRoutes = PathRoutes
  environment = environment
  session: any

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.session = this.sessionService.getSession()
  }

  logout() {
    this.sessionService.destroySession()
    this.router.navigate([PathRoutes.Login])
  }

}
