<header class="bg-gray-200 pt-9 pb-11 d-none d-md-block">
  <div class="container-md">
    <div class="row align-items-center">
      <div class="col">
        <h1 class="font-weight-bold" *ngIf="title">
          {{ title }}
          <span class="badge badge-pill badge-secondary-soft ms-2" *ngIf="counter">{{counter}}</span>
        </h1>
        <p class="font-size-lg text-white-75 mb-0" *ngIf="subtitle">
          {{ subtitle }}
        </p>
      </div>
      <ng-content *ngIf="custom"></ng-content>
      <div class="col-auto pr-0" *ngIf="button2Label">
        <button class="btn btn-sm" [ngClass]="{'btn-light btn-del': active, 'btn-success': !active}"
          (click)="button2Clicked()">
          {{ button2Label }}
        </button>
      </div>
      <div class="col-auto" *ngIf="button1Label">
        <app-button [label]="button1Label" (action)="button1Clicked()" [icon]="buttonIcon">
        </app-button>
      </div>
    </div>
  </div>
</header>