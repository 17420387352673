import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() form: FormGroup = null
  @Input() label: string = null
  @Input() required: boolean = false
  @Input() type: string = 'text'
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() price: any = ''
  @Input() mask: any = ''
  @Input() currency: boolean = false
  @Input() dropSpecialCharacters: boolean = true
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() value: string = ''
  @Input() tip: string = ''
  @Input() disabled: boolean = false
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeEmitted(e) {
    this.change.emit(e)
  }

}
