import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  SESSION_KEY = 'GENTE_SESSION_KEY'
  TOKEN_KEY = 'GENTE_TOKEN_KEY'
  REGISTER_KEY = 'GENTE_REGISTER_KEY'

  sessionBehavior = new BehaviorSubject<any>(this.getSession())
  sessionWatcher = this.sessionBehavior.asObservable()

  constructor() { }

  saveToken(token) {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  saveSession(params) {
    localStorage.setItem(this.SESSION_KEY, JSON.stringify(params))
  }

  getSession() {
    return localStorage.getItem(this.SESSION_KEY) ? JSON.parse(localStorage.getItem(this.SESSION_KEY)) : null
  }

  destroySession() {
    localStorage.clear()
  }

  saveRegister(params) {
    localStorage.setItem(this.REGISTER_KEY, JSON.stringify(params))
  }

  getRegister() {
    return localStorage.getItem(this.REGISTER_KEY) ? JSON.parse(localStorage.getItem(this.REGISTER_KEY)) : null
  }
}
