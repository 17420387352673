import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() link: any
  @Input() linkName: string
  @Input() sublink: any
  @Input() sublinkName: string
  @Input() current: string

  constructor() { }

  ngOnInit(): void {
  }

}
