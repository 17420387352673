import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { AccountService } from 'src/app/services/account.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-register-dependents',
  templateUrl: './register-dependents.component.html',
  styleUrls: ['./register-dependents.component.scss']
})
export class RegisterDependentsComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  addressForm: FormGroup
  relations = []
  loading = false
  ufs = []
  maritals = []
  session: any
  genders = [
    { id: 'masculino', name: 'Masculino' },
    { id: 'feminino', name: 'Feminino' },
  ]
  state: any
  terms = false
  user: any
  dependents: any
  dependentsArr = []
  isSameAddress = false

  constructor(
    public helper: HelperService,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private sessionService: SessionService,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute
  ) {
    this.state = this.router.getCurrentNavigation().extras.state
  }

  ngOnInit(): void {
    this.dependents = this.activatedRoute.snapshot.queryParams['dependents']

    this.ufs = this.helper.ufs
    // this.user = this.sessionService.getRegister()
    this.session = this.sessionService.getSession()
    this.setupForm()
    this.getRelationship()
    this.getMaritals()
  }

  async getRelationship() {
    try {
      let res = await this.userService.relationship()
      this.relations = res.map(e => ({ ...e, name: e.title }))
    } catch (error) {
      console.log(error)
    }
  }

  async getMaritals() {
    try {
      let res = await this.userService.maritalStatus()
      this.maritals = res.map(e => ({ ...e, name: e.title }))
    } catch (error) {
      console.log(error)
    }
  }

  setupForm() {
    this.addressForm = this.formBuilder.group({
      'street': ['', Validators.required],
      'street_number': ['', Validators.required],
      'neighborhood': ['', Validators.required],
      'city': ['', Validators.required],
      'state': ['', Validators.required],
      'zipcode': ['', Validators.required],
      'complementary': [''],
    })

    this.form = this.formBuilder.group({
      'name': ['', Validators.required],
      'email': ['', Validators.required],
      'rg': ['', Validators.required],
      'cpf': ['', Validators.required],
      'landline': ['', Validators.required],
      'marital': ['', Validators.required],
      'profession': ['', Validators.required],
      'gender': ['', Validators.required],
      'birthdate': ['', Validators.required],
      'relation': ['', Validators.required],
      'address': this.addressForm
    })
  }

  cepComplete() {
    if (this.addressForm.get('zipcode').value.length == 8) {
      return this.accountService.getCep(this.addressForm.get('zipcode').value)
        .then(res => {

          var uf = this.ufs.filter(r => r.id == res.uf)[0]?.id

          this.addressForm.patchValue({
            street: res.logradouro,
            neighborhood: res.bairro,
            city: res.localidade,
            state: uf,
            // complement: res.complemento
          });
        })
        .catch(error => {
          console.log(error)
          this.addressForm.get('zipcode').setValue(null)
        });
    }
  }

  sameAddress() {
    console.log(this.isSameAddress)
    let register = this.sessionService.getRegister()
    this.addressForm.get('street').setValue(this.isSameAddress ? register.info.address.street : '')
    this.addressForm.get('street_number').setValue(this.isSameAddress ? register.info.address.street_number : '')
    this.addressForm.get('neighborhood').setValue(this.isSameAddress ? register.info.address.neighborhood : '')
    this.addressForm.get('city').setValue(this.isSameAddress ? register.info.address.city : '')
    this.addressForm.get('state').setValue(this.isSameAddress ? register.info.address.state : '')
    this.addressForm.get('zipcode').setValue(this.isSameAddress ? register.info.address.zipcode : '')
    this.addressForm.get('complementary').setValue(this.isSameAddress ? register.info.address.complementary : '')
  }

  async save() {
    this.loading = true

    this.dependentsArr.push({ ...this.form.getRawValue(), mobile: this.form.get('landline').value })
    this.dependents = this.dependents - 1
    console.log(this.dependentsArr)

    setTimeout(() => {
      if (this.dependents > 0) {
        this.form.reset()
      } else {
        let register = this.sessionService.getRegister()
        let params = { ...register, dependents: this.dependentsArr }
        console.log(params)
        this.sessionService.saveRegister(params)
        this.router.navigate([PathRoutes.Register.Payment])
      }
      this.loading = false
    }, 1000);
  }

  logout() {
    this.sessionService.destroySession()
    this.router.navigate([PathRoutes.Login])
  }
}
