<div class="form-group mb-0">
  <button class="btn btn-sm" (click)="actionEmittted()" *ngIf="!link" [disabled]="disabled" [ngClass]="customClass ? customClass : 'btn-primary'">
    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
    {{ label }}
    <span *ngIf="icon" [ngClass]="icon" class="ml-1"></span>
  </button>
  <a class="btn btn-sm" routerLink="{{ link }}" *ngIf="link" [ngClass]="customClass ? customClass : 'btn-primary'">
    {{ label }}
    <span *ngIf="icon" [ngClass]="icon" class="ml-1"></span>
  </a>
</div>
