<main class="pb-8 pb-md-11 mt-md-n6">
  <div class="container-md">
    <div class="row">
      <!-- <div class="col-12 col-md-3">
        <app-sidebar></app-sidebar>
      </div> -->
      <div class="col-12 col-md-12">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</main>
