import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {
  @Input() form: FormGroup = null
  @Input() label: string = null
  @Input() required: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() tip: string = ''

  type: string = 'password'

  constructor() { }

  ngOnInit(): void {
  }

  changeType(t) {
    this.type = t == 'password' ? 'text' : 'password'
  }

}
