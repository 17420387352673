import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  termsOfUse: any
  constructor(
    public helper: HelperService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'name': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'cpf': ['', Validators.compose([Validators.required, this.helper.ValidateCPF])],
      'mobile': ['', Validators.required],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      'confirm_password': '',
      'terms': [false],
    })
  }

  async register() {
    this.loading = true
    try {
      let res: any = await this.userService.save(this.form.getRawValue())
      console.log(res)
      if (res.status) {
        Swal.fire({
          title: 'Sucesso',
          text: `Um email para confirmação foi enviado para ${this.form.get('email').value}. Acesse o link para validar seu cadastro`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          // denyButtonText: `Don't save`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.router.navigate([PathRoutes.Login])
            // Swal.fire('Saved!', '', 'success')
          } else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
          }
        })
      } else {
        this.helper.triggerNotification(false, 'Erro', res.error)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

}
