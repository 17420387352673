<div class="form-group" [formGroup]="form">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <div class="input-group">
    <input [type]=" type" [formControlName]="name" class="form-control form-control-sm" [placeholder]="placeholder"
      [ngClass]="ngClass">
    <div class="input-group-append">
      <button class="btn btn-sm btn-outline-custom" type="button" (click)="changeType(type)"
        [ngClass]="{'border-danger': messageCondition }">
        <i *ngIf="type == 'text'" class="fas fa-eye"></i>
        <i *ngIf="type == 'password'" class="fas fa-eye-slash"></i>
      </button>
    </div>
  </div>
  <small class="text-muted" *ngIf="!messageCondition">{{ tip }}</small>

  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
