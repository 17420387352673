import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private network: NetworkService) { }

  list(dependents) {
    return this.network.get(`${Endpoints.Plan}?dependents=${dependents || 0}`)
  }

}
