<app-navbar></app-navbar>
<app-header title="Associar-se ao Clube Vida"></app-header>

<app-section>
  <app-card>
    <div class="progress" style="height: 5px;">
      <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </app-card>
  <app-card title="Informações de conta">
    <form [formGroup]="form" (submit)="register()">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="Nome" type="text" name="name" placeholder="Qual seu nome?" [form]="form"
            [ngClass]="{'is-invalid': form.controls.name.touched && form.controls.name.invalid}"
            [messageCondition]="form.controls.name.touched && form.controls.name.invalid" message="Digite seu nome">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="CPF" type="text" name="cpf" placeholder="Qual seu CPF?" [form]="form"
            [ngClass]="{'is-invalid': form.controls.cpf.touched && form.controls.cpf.invalid}" [mask]="helper.masks.cpf"
            [messageCondition]="form.controls.cpf.touched && form.controls.cpf.invalid" message="Digite seu cpf">
          </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="Telefone" type="text" name="mobile" placeholder="Qual seu telefone?" [form]="form"
            [ngClass]="{'is-invalid': form.controls.mobile.touched && form.controls.mobile.invalid}"
            [mask]="helper.masks.mobile"
            [messageCondition]="form.controls.mobile.touched && form.controls.mobile.invalid"
            message="Digite seu telefone">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Email" type="text" name="email" placeholder="Qual seu email?" [form]="form"
            [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid }"
            [messageCondition]="form.controls.email.touched && form.controls.email.invalid "
            [message]="'Digite um email válido'">
          </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input-password label="Senha" name="password" placeholder="Insira uma senha" [form]="form"
            [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid}"
            [messageCondition]="form.controls.password.touched && form.controls.password.invalid"
            message="Preencha a senha">
          </app-input-password>
        </div>
        <div class="col-12 col-md-6">
          <app-input-password label="Confirmar senha" name="confirm_password" placeholder="Repita a senha" [form]="form"
            [ngClass]="{'is-invalid': form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value}"
            [messageCondition]="form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value"
            message="As senhas não conferem">
          </app-input-password>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="terms">
            <label class="form-check-label" for="terms">
              Declaro ter lido e estar de acordo com o <a href="" data-toggle="modal"
                data-target="#modalTermsOfUse">termos de uso</a>
            </label>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-7">
        <div class="col-12">
          <app-form-button [loading]="loading" label="Continuar" [form]="form"
            [disabled]="loading || form.invalid || form.controls['password'].value != form.controls['confirm_password'].value || !form.controls.terms.value">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-section>
<app-modal title="Termos de uso" identifier="modalTermsOfUse" large="true">
  <div [innerHtml]="termsOfUse"></div>
</app-modal>
