<!-- <div class="card card-bleed shadow-light-lg mb-6 card-border border-primary"> -->
<div class="card shadow-light-lg mb-6">
  <div class="card-header" *ngIf="title">
    <h4 class="mb-0">
      {{ title }}
    </h4>
  </div>
  <div class="card-body">
    <app-loading *ngIf="loading"></app-loading>
    <ng-content *ngIf="!loading"></ng-content>
  </div>
</div>


<!-- <section>
  <div class="container">

    <div class="row">

      <div class="col-12 col-md-6 col-lg-4">

        <div class="card  shadow-lg mb-6 mb-md-8 lift lift-lg">
          <div class="card-body text-center">
            <div class="icon-circle bg-success text-white mb-5">
              <i class="fe fe-clock"></i>
            </div>
            <h4 class="font-weight-bold">
              Integrations
            </h4>
            <p class="text-gray-700 mb-5">
              Connecting with 3rd party apps to exchange data.
            </p>
            <span class="badge badge-pill badge-dark-soft">
              <span class="h6 text-uppercase">
                9 entries
              </span>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</section> -->