import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent implements OnInit {
  @Input() form: FormGroup
  @Input() hasLogo: boolean = false
  @Input() id: boolean = false
  @Input() files = []
  @Input() name: string = ''
  @Input() imageUrl: any = null
  @Input() itemImageUrl = ''
  @Output() cancelUpload: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  environment = environment

  cancelUploadClicked(): void {
    this.hasLogo = !this.hasLogo
    this.cancelUpload.emit();
  }

  onSelectClicked(event): void {
    this.onSelect.emit(event);
  }

  onRemoveClicked(file): void {
    this.onRemove.emit(file);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
