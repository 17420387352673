import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() form: FormGroup = null
  @Input() label: string = ''
  @Input() required: boolean = false
  @Input() rows = 5
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() ngClass: any = ''
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Input() value: string = ''
  @Input() disabled: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

}
