<app-navbar></app-navbar>
<app-header title="Associar-se ao Clube Vida"></app-header>
<app-section>
  <app-card>
    <div class="progress" style="height: 5px;">
      <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </app-card>
  <app-card [loading]="loading" title="Faça parte do Clube Vida com Saúde">
    <form [formGroup]="form" (submit)="register()">
      <div class="row">
        <div class="col-12 col-md-7">

          <div class="mb-5">
            <h4 class="mb-0">Titular</h4>
            <h3 class="mb-0" *ngIf="!loadingPrice">R${{ item.amount }}</h3>
            <p>
              <span *ngIf="loadingPrice" class="spinner-border spinner-border-sm"></span>
            </p>
            <small class="text-gray-700">
              Valor somente para titular
            </small>
          </div>

          <div class="mb-5">
            <h4 class="mb-0">Deseja incluir dependente?</h4>
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [(ngModel)]="item.hasDependents" id="inlineRadio1"
                  (click)="changeHasDependents(false)" [ngModelOptions]="{standalone: true}" [value]="false">
                <label class="form-check-label" for="inlineRadio1">Não incluir dependentes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" [(ngModel)]="item.hasDependents" id="inlineRadio2"
                  (click)="changeHasDependents(true)" [ngModelOptions]="{standalone: true}" [value]="true">
                <label class="form-check-label" for="inlineRadio2">Sim, quero incluir dependentes</label>
              </div>
            </div>
            <small class="text-gray-700">
              O valor adicional pra cada dependente incluso é de R$50,00
            </small>
          </div>

          <div class="mb-5" *ngIf="item.hasDependents">
            <h4>Quantos dependentes deseja adicionar?</h4>
            <div class="d-flex align-items-center">
              <button class="btn btn-sm btn-outline-primary" type="button" (click)="removeDependent()">
                <i class="fas fa-minus-circle"></i>
              </button>
              <strong>
                <h4 class="mb-0 mx-5">{{ item.dependents }}</h4>
              </strong>
              <button class="btn btn-sm btn-outline-primary" type="button" (click)="addDependent()">
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
          </div>

          <div class="mb-5">
            <h4>Escolha as parcelas</h4>
            <app-select placeholder="Selecione as parcelas" [form]="form" name="installments" [options]="installments"
              [optionsId]="true" (change)="changeInstallments($event)"
              [ngClass]="{'is-invalid': form.controls.installments.touched && form.controls.installments.invalid}"
              [messageCondition]="form.controls.installments.touched && form.controls.installments.invalid"
              message="Selecione as parcelas">
            </app-select>
          </div>

        </div>

        <div class="col-12 col-md-5">
          <div class="card shadow-lg mb-6 mb-md-0">
            <div class="card-body">
              <div class="text-center mb-3">
                <span class="badge rounded-pill bg-primary-soft">
                  <span class="h6 text-uppercase">Valor total do beneficiário</span>
                </span>
              </div>
              <div class="d-flex justify-content-center">
                <div class="py-4">
                  <span *ngIf="loadingPrice" class="spinner-border spinner-border-lg"></span>
                </div>
                <span *ngIf="!loadingPrice">
                  <span class="h2 mb-0 mt-2">R$ </span>
                  <span class="price display-2 mb-0" *ngIf="!loadingPrice" data-annual="0"
                    data-monthly="0">{{item.amount}}</span>
                  <span class="h2 align-self-end mb-1">/ano</span>
                </span>
              </div>
              <p class="text-center text-muted mb-5">
                <span>1 titular</span>
                <span *ngIf="item.hasDependents && item.dependents == 1"> + {{
                  item.dependents}} depentente</span>
                <span *ngIf="item.hasDependents && item.dependents > 1"> + {{
                  item.dependents}} depententes</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <app-form-button [loading]="loading" label="Continuar" [form]="form" [disabled]="loading || form.invalid">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-section>
