<app-navbar></app-navbar>
<app-header title="Associar-se ao Clube Vida"></app-header>

<app-section>
  <app-card>
    <div class="progress" style="height: 5px;">
      <div class="progress-bar" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </app-card>
  <app-card [loading]="loading" title="Informações de pagamento">
    <form [formGroup]="form" (submit)="grabCardHash()">
      <div class="row">
        <div class="col-12 col-md-7">
          <app-input label="Nome no cartão" type="text" name="card_holder_name" placeholder="João Silva" [form]="form"
            [ngClass]="{'is-invalid': form.controls.card_holder_name.touched && form.controls.card_holder_name.invalid}"
            [messageCondition]="form.controls.card_holder_name.touched && form.controls.card_holder_name.invalid"
            message="Digite o nome que está no cartão" tip="Digite o nome que está no cartão">
          </app-input>
          <app-input label="Número do cartão" type="text" name="card_number" placeholder="4111 1111 1111 1111"
            [form]="form" [mask]="helper.masks.credit_card"
            [ngClass]="{'is-invalid': form.controls.card_number.touched && form.controls.card_number.invalid}"
            [messageCondition]="form.controls.card_number.touched && form.controls.card_number.invalid"
            message="Digite o número do cartão" tip="Digite o número do cartão">
          </app-input>
          <app-input label="Data de validade" type="text" name="card_expiration_date" placeholder="12/29" [form]="form"
            [mask]="'m0/00'"
            [ngClass]="{'is-invalid': form.controls.card_expiration_date.touched && form.controls.card_expiration_date.invalid}"
            [messageCondition]="form.controls.card_expiration_date.touched && form.controls.card_expiration_date.invalid"
            message="Digite a data de validade" tip="Digite a data de validade">
          </app-input>
          <app-input label="CVV" type="text" name="card_cvv" placeholder="123" [form]="form"
            [ngClass]="{'is-invalid': form.controls.card_cvv.touched && form.controls.card_cvv.invalid}"
            [messageCondition]="form.controls.card_cvv.touched && form.controls.card_cvv.invalid" message="Digite o cvv"
            tip="Digite o cvv">
          </app-input>
          <app-select label="Alterar as parcelas" placeholder="Selecione as parcelas" [form]="form" name="installments"
            [options]="installments" [optionsId]="true" (change)="changeInstallments($event)"
            [ngClass]="{'is-invalid': form.controls.installments.touched && form.controls.installments.invalid}"
            [messageCondition]="form.controls.installments.touched && form.controls.installments.invalid"
            message="Selecione as parcelas">
          </app-select>

        </div>

        <div class="col-12 col-md-5">
          <div class="card shadow-lg mb-6 mb-md-0">
            <div class="card-body">
              <div class="text-center mb-3">
                <span class="badge rounded-pill bg-primary-soft">
                  <span class="h6 text-uppercase">Valor total do beneficiário</span>
                </span>
              </div>
              <div class="d-flex justify-content-center">
                <div class="py-4">
                  <span *ngIf="loadingPrice" class="spinner-border spinner-border-lg"></span>
                </div>
                <span *ngIf="!loadingPrice">
                  <span class="h2 mb-0 mt-2">R$ </span>
                  <span class="price display-2 mb-0" *ngIf="!loadingPrice" data-annual="0"
                    data-monthly="0">{{item.amount}}</span>
                  <span class="h2 align-self-end mb-1">/ano</span>
                </span>
              </div>
              <p class="text-center text-muted mb-5">
                <span>1 titular</span>
                <span *ngIf="item.hasDependents && item.dependents == 1"> + {{
                  item.dependents}} depentente</span>
                <span *ngIf="item.hasDependents && item.dependents > 1"> + {{
                  item.dependents}} depententes</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <app-form-button [loading]="submitting" label="Continuar" [form]="form" [disabled]="loading || form.invalid">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-section>
