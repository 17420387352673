<app-navbar></app-navbar>
<app-header title="Cadastrar"></app-header>

<app-section>
  <app-card title="Informações de conta">
    <form [formGroup]="form" (submit)="register()">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="Nome" type="text" name="name" placeholder="João Silva" [form]="form"
            [ngClass]="{'is-invalid': form.controls.name.touched && form.controls.name.invalid}"
            [messageCondition]="form.controls.name.touched && form.controls.name.invalid" message="Digite seu nome"
            tip="Digite seu nome">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="CPF" type="text" name="cpf" placeholder="000.000.000-00" [form]="form"
            [ngClass]="{'is-invalid': form.controls.cpf.touched && form.controls.cpf.invalid}" [mask]="helper.masks.cpf"
            [messageCondition]="form.controls.cpf.touched && form.controls.cpf.invalid" message="Digite seu cpf"
            tip="Digite seu cpf">
          </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="Telefone" type="text" name="mobile" placeholder="(00) 00000-0000" [form]="form"
            [ngClass]="{'is-invalid': form.controls.mobile.touched && form.controls.mobile.invalid}"
            [mask]="helper.masks.mobile"
            [messageCondition]="form.controls.mobile.touched && form.controls.mobile.invalid"
            message="Digite seu telefone" tip="Digite seu telefone">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Email" type="text" name="email" placeholder="nome@email.com" [form]="form"
            [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid }"
            [messageCondition]="form.controls.email.touched && form.controls.email.invalid "
            [message]="'Digite um email válido'" tip="Digite seu email">
          </app-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input-password label="Senha (mínimo de 8 caracteres)" name="password" placeholder="********"
            [form]="form" [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid}"
            [messageCondition]="form.controls.password.touched && form.controls.password.invalid"
            message="Preencha a senha (mínimo de 8 caracteres)" tip="Insira uma senha">
          </app-input-password>
        </div>
        <div class="col-12 col-md-6">
          <app-input-password label="Confirmar senha" name="confirm_password" placeholder="********" [form]="form"
            [ngClass]="{'is-invalid': form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value}"
            [messageCondition]="form.controls['confirm_password'].touched && form.controls['password'].value != form.controls['confirm_password'].value"
            message="As senhas não conferem" tip="Repita a senha">
          </app-input-password>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="terms" formControlName="terms">
            <label class="form-check-label" for="terms">
              Declaro ter lido e estar de acordo com o <a href="" data-toggle="modal"
                data-target="#modalTermsOfUse">termos de uso</a>
            </label>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-7">
        <div class="col-12">
          <app-form-button [loading]="loading" label="Continuar" [form]="form"
            [disabled]="loading || form.invalid || form.controls['password'].value != form.controls['confirm_password'].value || !form.controls.terms.value">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-section>
<app-modal title="Termos de uso" identifier="modalTermsOfUse" large="true">
  <!-- <div [innerHtml]="https://app.gentesaude.com.br/terms/show"></div> -->
  <iframe src="https://app.gentesaude.com.br/terms/show" width="100%" height="500px" frameborder="0"></iframe>
</app-modal>
