import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  notificationBehavior = new BehaviorSubject<Notification>(null)
  notificationWatcher = this.notificationBehavior.asObservable()

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public masks = {
    credit_card: '0000 0000 0000 0000',
    mobile: "(00) 00000-0000",
    landline: "(00) 0000-0000",
    cep: "00000-000",
    cpf: "000.000.000-00",
    cnpj: "00.000.000/0000-00",
    cpf_cnpj: "000.000.000-00||00.000.000/0000-00",
    nickname: "@A{20}",
    time: 'Hh:m0',
    date: 'd0/M0/0000',
    number: (qtd) => {
      var n = ''
      for (var i = 0; i < qtd; i++) {
        n += '0'
      }
      return n
    }
  }
  public ufs = [
    { "name": "Acre", "id": "AC" },
    { "name": "Alagoas", "id": "AL" },
    { "name": "Amapá", "id": "AP" },
    { "name": "Amazonas", "id": "AM" },
    { "name": "Bahia", "id": "BA" },
    { "name": "Ceará", "id": "CE" },
    { "name": "Distrito Federal", "id": "DF" },
    { "name": "Espírito Santo", "id": "ES" },
    { "name": "Goiás", "id": "GO" },
    { "name": "Maranhão", "id": "MA" },
    { "name": "Mato Grosso", "id": "MT" },
    { "name": "Mato Grosso do Sul", "id": "MS" },
    { "name": "Minas Gerais", "id": "MG" },
    { "name": "Pará", "id": "PA" },
    { "name": "Paraíba", "id": "PB" },
    { "name": "Paraná", "id": "PR" },
    { "name": "Pernambuco", "id": "PE" },
    { "name": "Piauí", "id": "PI" },
    { "name": "Rio de Janeiro", "id": "RJ" },
    { "name": "Rio Grande do Norte", "id": "RN" },
    { "name": "Rio Grande do Sul", "id": "RS" },
    { "name": "Rondônia", "id": "RO" },
    { "name": "Roraima", "id": "RR" },
    { "name": "Santa Catarina", "id": "SC" },
    { "name": "São Paulo", "id": "SP" },
    { "name": "Sergipe", "id": "SE" },
    { "name": "Tocantins", "id": "TO" }

  ]

  public months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  public weekDays = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo']

  constructor() { }

  toCurrencyString(value, type = 1) {
    if (!value) return `R$0,00`
    switch (type) {
      case 1:
        const v = value.toFixed(2).replace('.', ',')
        return `R$${v}`
      case 2:
        let val = value.toString()
        let half = val.length - 2
        let res = `${val.substr(0, half)},${val.substr(half)}`;
        return `R$${res}`
    }
  }

  triggerNotification(success, title = '', text = '', timer = 4000) {
    let notification: any = {}

    notification.title = title || (success ? 'Informações salvas' : 'Erro ao salvar')
    notification.text = text || (success ? 'As informações foram salvas com sucesso' : 'Ocorreu um erro ao salvar as informações')
    notification.icon = success ? 'success' : 'error'
    notification.showCloseButton = true
    notification.showConfirmButton = false
    notification.timer = timer

    Swal.fire(notification)
  }
  getFormattedPrice(price: number, cifrao = true) {
    var type = cifrao ? 'currency' : 'decimal'
    return new Intl.NumberFormat('pt-BR', { style: type, currency: 'BRL' }).format(price);
  }

  getStringDate(date) {
    var d = new Date(date)
    return `${this.weekDays[d.getDay() - 1]}, ${d.getDate()} de ${this.months[d.getMonth()]} de ${d.getFullYear()}`
  }

  flatpickrDefaultDateFormatter() {
    var date = new Date(),
      month = (date.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      monthF = (month.length == 1) ? '0' + month : month,
      yearF = date.getFullYear();
    var dateInitial = (new Date(yearF, parseInt(month), 1)).getDate().toString();
    var dateI = (dateInitial.length == 1) ? '0' + dateInitial : dateInitial
    var dateFinal = (new Date(yearF, parseInt(month), 0)).getDate().toString();
    var dateF = (dateFinal.length == 1) ? '0' + dateFinal : dateFinal
    return `${dateI}/${monthF}/${yearF} até ${dateF}/${monthF}/${yearF}`
  }

  cron(date, status, updated) {
    let a = date.split(' ')
    let b = a[1].split(':')

    let c = updated.split(' ')
    let e = c[1].split(':')

    let d = new Date()
    let hours = d.getHours()
    let minutes = d.getMinutes()

    let m, h

    if (status == 'concluido') {
      m = e[1] - b[1]
      if (m < 0) {
        m = m + 60
        h = e[0] - b[0] - 1
        if (h < 0) {
          h = h + 24
        }
      } else {
        h = e[0] - b[0]
        if (h < 0) {
          h = h + 24
        }
      }
    } else {
      m = minutes - b[1]
      if (m < 0) {
        m = m + 60
        h = hours - b[0] - 1
        if (h < 0) {
          h = h + 24
        }
      } else {
        h = hours - b[0]
        if (h < 0) {
          h = h + 24
        }
      }
    }

    if (status == 'cancelado') {
      return '---'
    } else {
      return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m)
    }
  }

  groupBy(collection, property) {
    var i = 0, val, index,
      values = [], result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1)
        result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }

    return result.map(function (r, index) {
      return { ...r[0], qtd: r.length }
    })
  }

  getDateStringFromDate(date: Date, format: string = 'pt-BR'): string {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return format === 'pt-BR' ? [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      date.getFullYear()
    ].join('/') :

      [
        date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd,

      ].join('-')
      ;
  };

  customSwal() {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-outline-secondary btn-del mr-2'
      },
      buttonsStyling: false
    })
  }

  cancelSwal(active) {
    return this.customSwal().fire({
      title: 'Você tem certeza?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: active == 1 ? 'Sim, desbloquear!' : 'Sim, bloquear',
      cancelButtonText: 'Cancelar'
    })
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  timer(df, dt, status = false) {
    var dateTo
    if (status) {
      dateTo = new Date(dt).getTime()
    } else {
      dateTo = new Date().getTime()
    }
    var dateFrom = new Date(df).getTime()
    var delta = Math.abs(dateTo - dateFrom) / 1000;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) //% 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = Math.floor(delta % 60);

    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
      if (days > 0) {
        return this.pad(days) + ' dia(s)'
      } else {
        let timer = { hours: this.pad(hours), minutes: this.pad(minutes), seconds: this.pad(seconds) }
        return timer
      }
    } else {
      return '00:00:00'
    }

  }

  deleteSwal(text = null) {
    return this.customSwal().fire({
      title: 'Você tem certeza?',
      icon: 'warning',
      text: text,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    })
  }

  getType(type) {
    switch (type) {
      case 'market':
        return 'Mercado'
      case 'restaurant':
        return 'Restaurante'
      case 'distributor':
        return 'Distribuidor'
    }
  }

  getAccountType(type) {
    switch (type) {
      case 'conta_corrente':
        return 'Conta corrente'
      case 'conta_poupanca':
        return 'Conta poupança'
    }
  }

  public banksArray = [
    {
      "id": "356",
      "name": "ABN Amro Real"
    },
    {
      "id": "204",
      "name": "American Express Bank"
    },
    {
      "id": "039",
      "name": "BEP"
    },
    {
      "id": "752",
      "name": "BNP Paribas"
    },
    {
      "id": "069",
      "name": "BPN Brasil"
    },
    {
      "id": "741",
      "name": "BRP"
    },
    {
      "id": "044",
      "name": "BVA"
    },
    {
      "id": "654",
      "name": "Banco A.J. Renner"
    },
    {
      "id": "246",
      "name": "Banco ABC Brasil"
    },
    {
      "id": "025",
      "name": "Banco Alfa"
    },
    {
      "id": "213",
      "name": "Banco Arbi"
    },
    {
      "id": "107",
      "name": "Banco BBM"
    },
    {
      "id": "096",
      "name": "Banco BMF"
    },
    {
      "id": "318",
      "name": "Banco BMG"
    },
    {
      "id": "740",
      "name": "Banco Barclays"
    },
    {
      "id": "031",
      "name": "Banco Beg"
    },
    {
      "id": "036",
      "name": "Banco Bem"
    },
    {
      "id": "248",
      "name": "Banco Boavista Interatlântico"
    },
    {
      "id": "218",
      "name": "Banco Bonsucesso"
    },
    {
      "id": "225",
      "name": "Banco Brascan"
    },
    {
      "id": "075",
      "name": "Banco CR2"
    },
    {
      "id": "263",
      "name": "Banco Cacique"
    },
    {
      "id": "222",
      "name": "Banco Calyon Brasil"
    },
    {
      "id": "412",
      "name": "Banco Capital"
    },
    {
      "id": "040",
      "name": "Banco Cargill"
    },
    {
      "id": "745",
      "name": "Banco Citibank"
    },
    {
      "id": "241",
      "name": "Banco Clássico"
    },
    {
      "id": "753",
      "name": "Banco Comercial Uruguai"
    },
    {
      "id": "748",
      "name": "Banco Cooperativo Sicredi"
    },
    {
      "id": "756",
      "name": "Banco Cooperativo do Brasil"
    },
    {
      "id": "721",
      "name": "Banco Credibel"
    },
    {
      "id": "505",
      "name": "Banco Credit Suisse"
    },
    {
      "id": "229",
      "name": "Banco Cruzeiro"
    },
    {
      "id": "266",
      "name": "Banco Cédula"
    },
    {
      "id": "707",
      "name": "Banco Daycoval"
    },
    {
      "id": "214",
      "name": "Banco Dibens"
    },
    {
      "id": "265",
      "name": "Banco Fator"
    },
    {
      "id": "224",
      "name": "Banco Fibra"
    },
    {
      "id": "626",
      "name": "Banco Ficsa"
    },
    {
      "id": "175",
      "name": "Banco Finasa"
    },
    {
      "id": "233",
      "name": "Banco GE Capital"
    },
    {
      "id": "739",
      "name": "Banco Galvão de Negócios"
    },
    {
      "id": "734",
      "name": "Banco Gerdau"
    },
    {
      "id": "612",
      "name": "Banco Guanabara"
    },
    {
      "id": "604",
      "name": "Banco Industrial"
    },
    {
      "id": "320",
      "name": "Banco Industrial e Comercial"
    },
    {
      "id": "653",
      "name": "Banco Indusval"
    },
    {
      "id": "077",
      "name": "Banco Inter"
    },
    {
      "id": "630",
      "name": "Banco Intercap"
    },
    {
      "id": "184",
      "name": "Banco Itaú BBA"
    },
    {
      "id": "074",
      "name": "Banco J. Safra"
    },
    {
      "id": "217",
      "name": "Banco Joh Deere"
    },
    {
      "id": "076",
      "name": "Banco KDB"
    },
    {
      "id": "495",
      "name": "Banco La Provincia de Buenos Aires"
    },
    {
      "id": "600",
      "name": "Banco Luso Brasileiro"
    },
    {
      "id": "212",
      "name": "Banco Matone"
    },
    {
      "id": "394",
      "name": "Banco Mercantil de Crédito"
    },
    {
      "id": "389",
      "name": "Banco Mercantil do Brasil"
    },
    {
      "id": "755",
      "name": "Banco Merrill Lynch"
    },
    {
      "id": "746",
      "name": "Banco Modal"
    },
    {
      "id": "738",
      "name": "Banco Morada"
    },
    {
      "id": "488",
      "name": "Banco Morgan Guaranty"
    },
    {
      "id": "066",
      "name": "Banco Morgan Stanley Dean Witter"
    },
    {
      "id": "492",
      "name": "Banco NMB Postbank"
    },
    {
      "id": "735",
      "name": "Banco Neon"
    },
    {
      "id": "045",
      "name": "Banco Opportunity"
    },
    {
      "id": "208",
      "name": "Banco Pactual"
    },
    {
      "id": "623",
      "name": "Banco Panamericano"
    },
    {
      "id": "611",
      "name": "Banco Paulista"
    },
    {
      "id": "613",
      "name": "Banco Pecunia"
    },
    {
      "id": "643",
      "name": "Banco Pine"
    },
    {
      "id": "073",
      "name": "Banco Popular"
    },
    {
      "id": "638",
      "name": "Banco Prosper"
    },
    {
      "id": "747",
      "name": "Banco Rabobank International"
    },
    {
      "id": "633",
      "name": "Banco Rendimento"
    },
    {
      "id": "072",
      "name": "Banco Rural"
    },
    {
      "id": "453",
      "name": "Banco Rural"
    },
    {
      "id": "422",
      "name": "Banco Safra"
    },
    {
      "id": "351",
      "name": "Banco Santander"
    },
    {
      "id": "033",
      "name": "Banco Santander Banespa"
    },
    {
      "id": "353",
      "name": "Banco Santander Brasil"
    },
    {
      "id": "250",
      "name": "Banco Schahin"
    },
    {
      "id": "743",
      "name": "Banco Semear"
    },
    {
      "id": "749",
      "name": "Banco Simples"
    },
    {
      "id": "366",
      "name": "Banco Societe Generale Brasil"
    },
    {
      "id": "637",
      "name": "Banco Sofisa"
    },
    {
      "id": "243",
      "name": "Banco Stock Máxima"
    },
    {
      "id": "464",
      "name": "Banco Sumitomo Mitsui Brasileiro"
    },
    {
      "id": "456",
      "name": "Banco Tokyo Mitsubishi UFJ"
    },
    {
      "id": "634",
      "name": "Banco Triângulo"
    },
    {
      "id": "610",
      "name": "Banco VR"
    },
    {
      "id": "655",
      "name": "Banco Votorantim"
    },
    {
      "id": "370",
      "name": "Banco WestLB"
    },
    {
      "id": "003",
      "name": "Banco da Amazônia"
    },
    {
      "id": "070",
      "name": "Banco de Brasília – BRB"
    },
    {
      "id": "300",
      "name": "Banco de la Nación Argentina"
    },
    {
      "id": "001",
      "name": "Banco do Brasil"
    },
    {
      "id": "004",
      "name": "Banco do Nordeste"
    },
    {
      "id": "494",
      "name": "Banco la República Oriental del Uruguay"
    },
    {
      "id": "116",
      "name": "Banco Único"
    },
    {
      "id": "029",
      "name": "Banerj"
    },
    {
      "id": "047",
      "name": "Banese"
    },
    {
      "id": "038",
      "name": "Banestado"
    },
    {
      "id": "021",
      "name": "Banestes"
    },
    {
      "id": "719",
      "name": "Banif"
    },
    {
      "id": "037",
      "name": "Banpará"
    },
    {
      "id": "041",
      "name": "Banrisul"
    },
    {
      "id": "027",
      "name": "Besc"
    },
    {
      "id": "237",
      "name": "Bradesco"
    },
    {
      "id": "104",
      "name": "Caixa Econômica Federal"
    },
    {
      "id": "477",
      "name": "Citibank"
    },
    {
      "id": "487",
      "name": "Deutsche Bank"
    },
    {
      "id": "751",
      "name": "Dresdner Bank"
    },
    {
      "id": "252",
      "name": "Fininvest"
    },
    {
      "id": "399",
      "name": "HSBC"
    },
    {
      "id": "062",
      "name": "Hipercard"
    },
    {
      "id": "063",
      "name": "Ibibank"
    },
    {
      "id": "249",
      "name": "Investcred Unibanco"
    },
    {
      "id": "341",
      "name": "Itau"
    },
    {
      "id": "479",
      "name": "Itaubank (antigo Bank Boston)"
    },
    {
      "id": "652",
      "name": "Itaú Holding Financeira"
    },
    {
      "id": "376",
      "name": "JP Morgan"
    },
    {
      "id": "757",
      "name": "KEB"
    },
    {
      "id": "065",
      "name": "Lemon Bank"
    },
    {
      "id": "237",
      "name": "Next"
    },
    {
      "id": "151",
      "name": "Nossa Caixa"
    },
    {
      "id": "260",
      "name": "Nubank"
    },
    {
      "id": "254",
      "name": "Paraná Banco"
    },
    {
      "id": "347",
      "name": "Sudameris"
    },
    {
      "id": "409",
      "name": "Unibanco"
    },
    {
      "id": "230",
      "name": "Unicard"
    }
  ]

  getCaseStatus(s) {
    switch (s) {
      case 'open':
        return 'Aberto'
      case 'closed':
        return 'Encerrado'
    }
  }

  getGender(g) {
    switch (g) {
      case 'male':
        return 'Masculino'
      case 'female':
        return 'Feminino'
      case 'other':
        return 'Outro'
    }
  }

  paymentStatus(p) {
    switch (p) {
      case 'trialing':
        return { label: 'Teste grátis', color: 'badge-info' }
      case 'paid':
        return { label: 'Pago', color: 'badge-success' }
      case 'ended':
        return { label: 'Finalizado', color: 'badge-dark' }
      case 'pending_payment':
        return { label: 'Pagamento pendente', color: 'badge-warning' }
      case 'unpaid':
        return { label: 'Não pago', color: 'badge-danger' }
      case 'canceled':
        return { label: 'Cancelado', color: 'badge-danger' }
    }
  }

  paymentMethod(s) {
    switch (s) {
      case 'app_credit':
        return 'Crédito APP'

      case 'credit_card':
        return 'Cartão de Crédito'

      case 'bank_deposit':
        return 'Depósito Bancário'

      case 'on_site':
        return 'No local'

      case 'on_delivery':
        return 'Na entrega'
    }
  }

  transactionType(s) { //Types 'order','transfer','refound','prize','add_credit','withdraw'
    switch (s) {
      case 'order':
        return 'Pedido'
      case 'transfer':
        return 'Transferência'
      case 'refound':
        return 'Reembolso'
      case 'prize':
        return 'Prêmio'
      case 'add_credit':
        return 'Adicionar crédito'
      case 'withdraw':
        return 'Resgate'

    }
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  getCurrentDate(date) {
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    let split = date.split(' ')[0].split('-')
    return split[1] == currentMonth + 1 && split[0] == currentYear
  }

  ValidateCPF(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return { 'CPFNumberInvalid': true };
    var Soma;
    var Resto;
    var i;

    // control.value = control.value.replace(/[^\w\s]/gi, '').replace(' ', '')

    Soma = 0;
    if (control.value == "00000000000" || !control.value) return { 'CPFNumberInvalid': true };

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(control.value.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(control.value.substring(9, 10))) return { 'CPFNumberInvalid': true };

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(control.value.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(control.value.substring(10, 11))) return { 'CPFNumberInvalid': true };
    return null
  }
}
