<div class="form-group" [formGroup]="form" *ngIf="form">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <select [formControlName]="name" class="custom-select custom-select-sm" data-toggle="select" [ngClass]="customClass"
    (change)="changeEmitted($event)">
    <option value="">{{ placeholder }}</option>
    <option *ngFor="let option of options" [value]="optionsId ? option.id : option">
      {{ optionsId ? option.name : option }}</option>
  </select>
  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>

<div class="form-group" *ngIf="!form">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <select class="custom-select" data-toggle="select" [ngClass]="customClass ? customClass : 'custom-select-sm'"
    (change)="changeEmitted($event)">
    <option value="">{{ placeholder }}</option>
    <option *ngFor="let option of options" [value]="optionsId ? option.id : option">
      {{ optionsId ? option.name : option }}
    </option>
  </select>
  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
