import { HelperService } from 'src/app/helpers/helper.service';
import { Component, OnInit } from '@angular/core';
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

@Component({
  selector: 'app-flatpickr',
  templateUrl: './flatpickr.component.html',
  styleUrls: ['./flatpickr.component.scss']
})
export class FlatpickrComponent implements OnInit {
  Portuguese = Portuguese
  defaultDate = this.helper.flatpickrDefaultDateFormatter()
  selectedDate: any

  constructor(private helper: HelperService) { }

  ngOnInit() {
  }

}
