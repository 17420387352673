<section class="section-border border-primary">
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center gx-0 min-vh-100">
      <div class="col-12 col-md-5 py-8 py-md-11">

        <!-- Heading -->
        <h1 class="fw-bold text-center">
          <!-- Gente Saúde -->
          <img src="../../../assets/img/logo.png" width="200" />
        </h1>

        <!-- Text -->
        <p class="mb-6 text-center text-muted">
          Entre com os detalhes da sua conta
        </p>

        <!-- Form -->
        <form class="mb-6" [formGroup]="form" (submit)="login()">

          <!-- Email -->
          <app-input label="Email" type="text" name="email" placeholder="nome@email.com" [form]="form"
            [ngClass]="{'is-invalid': form.controls.email.touched && form.controls.email.invalid }"
            [messageCondition]="form.controls.email.touched && form.controls.email.invalid "
            [message]="'Digite um email válido'">
          </app-input>

          <!-- Password -->
          <app-input-password label="Senha" name="password" placeholder="Preencha sua senha" [form]="form"
            [ngClass]="{'is-invalid': form.controls.password.touched && form.controls.password.invalid}"
            [messageCondition]="form.controls.password.touched && form.controls.password.invalid"
            message="Preencha a senha">
          </app-input-password>

          <!-- Submit -->
          <app-form-button [loading]="loading" label="Entrar" [form]="form" [disabled]="loading || form.invalid">
          </app-form-button>

        </form>

        <!-- Text -->
        <p class="mb-0 fs-sm text-center text-muted">
          Ainda não possui uma conta? <a [routerLink]="PathRoutes.Register.User">Cadastrar-se</a>.
        </p>

      </div>
    </div> <!-- / .row -->
  </div> <!-- / .container -->
</section>
