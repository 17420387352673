import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string
  @Input() buttonIcon: string
  @Input() subtitle: string
  @Output() button1: EventEmitter<any> = new EventEmitter();
  @Input() button1Label: string
  @Output() button2: EventEmitter<any> = new EventEmitter();
  @Input() button2Label: string
  @Input() active: boolean
  @Input() custom: boolean
  @Input() counter: number

  button1Clicked(): void {
    this.button1.emit();
  }

  button2Clicked(): void {
    this.button2.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
