<div class="modal fade" [id]="identifier" tabindex="-1" role="dialog" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" [ngClass]="{'modal-lg': large}"role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-muted">{{ title }}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
