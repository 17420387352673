import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { PlanService } from 'src/app/services/plan.service';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-register-plan',
  templateUrl: './register-plan.component.html',
  styleUrls: ['./register-plan.component.scss']
})
export class RegisterPlanComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  termsOfUse: any
  installments = []
  item = {
    amount: '0',
    dependents: 1,
    hasDependents: false,
    installments_index: 0
  }
  filters = {
    dependents: 0
  }
  loadingPrice = false
  session: any

  constructor(
    public helper: HelperService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private userService: UsersService,
    private planService: PlanService
  ) { }

  ngOnInit(): void {
    this.setupForm()
    this.loading = true
    this.getPlan()
    this.session = this.sessionService.getSession()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'amount': ['', Validators.required],
      'installments': ['', Validators.required],
    })
  }

  addDependent() {
    let value = this.item.dependents + 1
    this.item.dependents = value
    this.filters.dependents = value
    this.getPlan()
  }

  removeDependent() {
    if (this.item.dependents == 1) {
      return
    }
    let value = this.item.dependents - 1
    this.item.dependents = value
    this.filters.dependents = value
    this.getPlan()
  }

  changeHasDependents(v) {
    this.item.dependents = v ? 1 : 0
    this.filters.dependents = v ? 1 : 0
    this.item.hasDependents = v
    this.getPlan()
  }

  async getPlan() {
    this.loadingPrice = true
    try {
      let res: any = await this.planService.list(this.filters.dependents)
      if (res.status) {
        console.log(res)
        this.item.amount = Number(res.value[this.item.installments_index].total_value).toFixed(2).replace('.', ',')
        this.form.get('amount').setValue(res.value[this.item.installments_index].total_value)
        this.installments = res.value.map((elem, index) => ({
          id: elem.installments,
          name: `${elem.installments}x de R$${Number(elem.value).toFixed(2).replace('.', ',')}`
        }))
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loading = false
      this.loadingPrice = false
    }
  }

  changeInstallments(event) {
    console.log(event)
    this.form.get('installments').setValue(event.target.value)
    this.item.installments_index = event.target.options.selectedIndex - 1
    this.getPlan()
  }

  register() {
    this.loading = true
    let register = this.sessionService.getRegister()
    let params = { ...register, plan: this.form.getRawValue() }
    this.sessionService.saveRegister(params)
    setTimeout(() => {
      this.loading = false
      this.router.navigate([PathRoutes.Register.Info], { queryParams: { dependents: this.filters.dependents } })
    }, 1000);
  }

}
