
export const Endpoints = Object.freeze({
  SignUp: 'misc/signup',
  Users: 'users',
  Plan: 'saude/prices',
  Login: 'misc/login',
  MaritalStatus: 'misc/maritalStatus',
  Relationship: 'misc/relationship',
  CreditCard: 'misc/creditcard ',
  Associate: 'saude/associate'
})
