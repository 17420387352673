<app-navbar></app-navbar>
<app-header title="Associar-se ao Clube Vida"></app-header>

<app-section>
  <app-card>
    <div class="progress" style="height: 5px;">
      <div class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </app-card>
  <app-card>
    <div class="row">
      <div class="col-12">
        <h1 class="display-3 font-weight-bold"> Parabéns! </h1>
        <p class="lead text-gray-700 mb-6">Você associou-se ao Clube Vida com sucesso.
          Para aproveitar suas vantagens, acesse o nosso aplicativo</p>
        <a class="text-reset d-inline-block mr-3 mb-3"
          href="https://apps.apple.com/br/app/gente-seguradora/id1318549047" target="_blank">
          <img alt="..." class="img-fluid" src="assets/img/button_app.svg">
        </a>
        <a class="text-reset d-inline-block"
          href="https://play.google.com/store/apps/details?id=com.phurshell.gente&hl=pt_BR&gl=US" target="_blank">
          <img alt="..." class="img-fluid" src="assets/img/button_play.svg">
        </a>
      </div>
    </div>
  </app-card>
</app-section>
