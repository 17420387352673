<nav class="navbar navbar-expand-lg navbar-light shadow">
  <div class="container-md">

    <!-- Brand -->
    <a class="" [routerLink]="PathRoutes.Register.Plan">
      <img src="./assets/img/logo.png" class="navbar-brand-img" alt="...">
    </a>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="session">
      <!--<ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="{{PathRoutes.Orders.List}}" routerLinkActive="active">
            Pedidos
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="{{PathRoutes.Products.List}}" routerLinkActive="active">
            Produtos
          </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="{{PathRoutes.Categories.List}}" routerLinkActive="active">
            Categorias
          </a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="{{PathRoutes.Rating}}" routerLinkActive="active">
            Avaliações
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="{{PathRoutes.Financial}}" routerLinkActive="active">
            Financeiro
          </a>
        </li>
      </ul> -->

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle d-flex align-items-center" id="navbarAccount" data-toggle="dropdown"
            href="#" aria-haspopup="true" aria-expanded="false">
            <span class="mr-2">
              {{session.name}}
            </span>
            <i class="fas fa-user-circle" style="font-size: xx-large;"></i>
            <!-- <div class="avatar avatar-lg">
              <img [src]="environment.baseUrl.img+image_url" alt="..." class="avatar-img rounded-circle">
            </div> -->
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarAccount">
            <!-- <a class="dropdown-link" routerLink="{{PathRoutes.MyAccount.Detail}}">
              <li class="dropdown-item">
                Minha conta
              </li>
            </a>
            <hr>
            <a class="dropdown-link" routerLink="{{PathRoutes.MyEstablishment.Detail}}">
              <li class="dropdown-item">
                Meu estabelecimento
              </li>
            </a>
            <hr> -->
            <a class="dropdown-link" routerLink="/" (click)="logout()">
              <li class="dropdown-item">
                Sair
              </li>
            </a>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
