import { PathRoutes } from 'src/app/routes/path-routes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { HelperService } from 'src/app/helpers/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false

  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    public helper: HelperService
  ) { }

  ngOnInit(): void {
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.email, Validators.required])],
      'password': ['', Validators.required]
    })
  }

  async login() {
    this.loading = true
    try {
      let res = await this.accountService.login(this.form.getRawValue())
      if (res.status) {
        this.router.navigate([PathRoutes.Register.Plan])
      } else {
        this.helper.triggerNotification(false, 'Erro', res.message || res.error)
      }
    } catch (error) {
      console.log(error)
      this.helper.triggerNotification(false, 'Erro', error)
    } finally {
      this.loading = false
    }
  }

}
