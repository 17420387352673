<div class="form-group" [formGroup]="form" *ngIf="!disabled">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <input *ngIf="!currency" [type]="type" [formControlName]="name" class="form-control form-control-sm"
    [placeholder]="placeholder" [ngClass]="ngClass" [mask]="mask" (blur)="changeEmitted($event)"
    [dropSpecialCharacters]="dropSpecialCharacters" />

  <input *ngIf="currency" [type]="type" [formControlName]="name" class="form-control form-control-sm"
    [placeholder]="placeholder" (blur)="changeEmitted($event)" [ngClass]="ngClass" currencyMask />

  <small class="text-muted" *ngIf="!messageCondition">{{ tip }}</small>

  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
<div class="form-group" *ngIf="disabled">
  <label class="text-muted" *ngIf="label">{{ label }} </label>
  <input class="form-control form-control-sm" [value]="value" [disabled]="true" [placeholder]="placeholder" />
</div>
