<div class="row align-items-start">
  <div class="col">

    <!-- SEARCH FIELD -->
    <form class="rounded shadow mb-4" *ngIf="hasSearch">
      <div class="input-group input-group-lg">
        <span class="input-group-text border-0 pe-1">
          <i class="fe fe-search"></i>
        </span>
        <input type="text" class="form-control border-0 px-1" aria-label="Procurar..." placeholder="Procurar..."
          (input)="searchEmitted($event)">
      </div>
    </form>

  </div>

  <!-- CUSTOM SELECT -->
  <div class="col-auto pl-0" *ngIf="selectOptions.length > 0">
    <app-select [customClass]="'custom-select-lg shadow pointer'" placeholder="Selecione a categoria"
      [options]="selectOptions" [optionsId]="true" (action)="selectEmitted($event)">
    </app-select>
  </div>

  <!-- ORDERING -->
  <div class="col-auto pl-0" *ngIf="hasOrder">
    <button class="btn btn-lg btn-outline-primary shadow" (click)="enableEmitted()" *ngIf="!moveEnabled">
      Ordenar
      <i class="fas fa-long-arrow-alt-up"></i>
      <i class="fas fa-long-arrow-alt-down"></i>
    </button>
    <button class="btn btn-lg btn-danger-soft shadow mr-2" (click)="enableEmitted()" *ngIf="moveEnabled">
      Cancelar
    </button>
    <button class="btn btn-lg btn-primary" (click)="orderEmitted()" *ngIf="moveEnabled">
      Concluir
    </button>
  </div>

</div>

<!-- TABLE -->
<div class="card shadow">
  <div class="table-responsive card-table">
    <table class="table table-hover table-sm table-nowarp mb-0">
      <thead class="text-uppercase text-muted">
        <tr>
          <th *ngFor="let column of columns">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-content *ngIf="!loading"></ng-content>
      </tbody>
    </table>
  </div>
  <app-empty-state *ngIf="empty && !loading"></app-empty-state>
  <app-loading *ngIf="loading"></app-loading>
</div>

<div class="row justify-content-center" *ngIf="more">
  <div class="col-12 col-md-9 col-lg-8 col-xl-7">
    <button (click)="loadMoreEmitted()" class="btn btn-block btn-outline-gray-300 d-flex align-items-center">
      <span class="mx-auto">Carregar Mais</span> <i class="fe fe-arrow-right"></i>
    </button>
  </div>
</div>