import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  text: string

  @Input() form: FormGroup = null
  @Input() label: string = ''
  @Input() required: boolean = false
  @Input() options: any = []
  @Input() optionsId: boolean = false
  @Input() name: string = ''
  @Input() placeholder: string = ''
  @Input() customClass: any = null
  @Input() messageCondition: any = ''
  @Input() message: string = ''
  @Output() getName: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeEmitted(event) {
    if (this.getName) {
      this.getName.emit(event.target.selectedOptions[0].label)
    }
    if (this.action) {
      this.action.emit(event.target.value)
    }
  }


}
