<div class="form-group">
  <span *ngIf="!hasLogo && id" class="align-self-end text-danger" style="cursor: pointer"
    (click)="cancelUploadClicked()">Cancelar</span>
  <div class="custom-dropzone" ngx-dropzone [multiple]="false" (change)="onSelectClicked($event)" *ngIf="!hasLogo">
    <ngx-dropzone-label>Arraste seu logo aqui</ngx-dropzone-label>
    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let file of files" [file]="file"
      [removable]="true" (removed)="onRemoveClicked(file)"></ngx-dropzone-image-preview>
  </div>
  <div [formGroup]="form">
    <input type="hidden" [(ngModel)]="imageUrl" [ngModelOptions]="{standalone: true}">
    <input type="hidden" [formControlName]="name" [value]="imageUrl">
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="hasLogo">
    <div class="avatar-custom d-flex justify-content-center align-items-center" (click)="hasLogo = !hasLogo">
      <img [src]="itemImageUrl ? environment.imageBaseUrl+itemImageUrl : 'assets/img/placeholder.jpg'"
        class="img-fluid screenshot">
      <span class="change-logo">
        Alterar Logo
      </span>
    </div>
  </div>
</div>
