<section class="section-border border-primary">
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center gx-0 min-vh-100">
      <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">

        <!-- Heading -->
        <h1 class="display-3 fw-bold text-center">
          Página não encontrada
        </h1>

        <!-- Text -->
        <p class="mb-5 text-center text-muted">
          Parece que você parou aqui por acidente

        </p>

        <!-- Link -->
        <div class="text-center">
          <a class="btn btn-primary" [routerLink]="PathRoutes.Register.Plan">
            Retornar
          </a>
        </div>

      </div>
    </div> <!-- / .row -->
  </div> <!-- / .container -->
</section>
