<div class="input-group input-group-merge">
<input class="form-control form-control-sm flatpickr-input flatpickr-custom" type="text" mwlFlatpickr [(ngModel)]="selectedDate"
  [altFormat]="'d/m/Y'" [altInput]="true" [convertModelValue]="true" [locale]="Portuguese" mode="range"
  [placeholder]="defaultDate">
<div class="input-group-append">
    <div class="input-group-text">
      <span class="fe fe-calendar"></span>
    </div>
  </div>
</div>
