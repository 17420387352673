// modules
import 'flatpickr/dist/flatpickr.css'
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './routes/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
// import { CurrencyMaskModule, CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask";
import { CKEditorModule } from 'ckeditor4-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';

// configs
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);

// export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
//   align: "left",
//   allowNegative: true,
//   decimal: ",",
//   precision: 2,
//   prefix: "R$ ",
//   suffix: "",
//   thousands: "."
// };

// components
import { SectionComponent } from './components/section/section.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CardComponent } from './components/card/card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TableComponent } from './components/table/table.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ModalComponent } from './components/modal/modal.component';
import { FlatpickrComponent } from './components/flatpickr/flatpickr.component';
import { SelectCustomComponent } from './components/select-custom/select-custom.component';
import { InputFileComponent } from './components/input-file/input-file.component';
// import { ContainerComponent } from './components/container/container.component';
import { ButtonComponent } from './components/button/button.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';

// pages
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterPlanComponent } from './pages/register/register-plan/register-plan.component';
import { RegisterUserInfoComponent } from './pages/register/register-user-info/register-user-info.component';
import { RegisterDependentsComponent } from './pages/register/register-dependents/register-dependents.component';
import { RegisterPaymentComponent } from './pages/register/register-payment/register-payment.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterSuccessComponent } from './pages/register/register-success/register-success.component';

@NgModule({
  declarations: [
    SectionComponent,
    HeaderComponent,
    NavbarComponent,
    SidebarComponent,
    CardComponent,
    LoadingComponent,
    BreadcrumbComponent,
    TableComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DropzoneComponent,
    FormButtonComponent,
    EmptyStateComponent,
    ModalComponent,
    FlatpickrComponent,
    SelectCustomComponent,
    InputFileComponent,
    // ContainerComponent,
    ButtonComponent,
    InputPasswordComponent,

    AppComponent,
    HomeComponent,
    NotFoundComponent,
    RegisterComponent,
    RegisterPlanComponent,
    RegisterUserInfoComponent,
    RegisterDependentsComponent,
    RegisterPaymentComponent,
    LoginComponent,
    RegisterSuccessComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FlatpickrModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    // CurrencyMaskModule,
    CKEditorModule,
    ImageCropperModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDcUxk8BLS7WpGnWCIMiy2cyPtXfE7Cho4",
      libraries: ["places"]
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    // { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
