import { Endpoints } from './../routes/endpoints';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private network: NetworkService, private sessionService: SessionService) { }

  list(params = null) {
    var url = `${Endpoints.Users}?type=${params?.type || ''}&page=${params?.page || ''}&per_page=${params?.per_page || ''}&q=${params?.search || ''}`;
    return this.network.get(url)
  }

  get(id) {
    var url = `${Endpoints.Users}/${id}`
    return this.network.get(url)
  }

  save(info) {
    var params = info
    if (params.id) {
      var id = params.id
      delete (params.id)
      return this.network.put(`${Endpoints.Users}/${id}`, params)
    } else {
      delete (params.id)
      // console.log(JSON.stringify(params, null, 3))
      return this.network.post(Endpoints.SignUp, params)
    }
  }

  updatePicture(id, params) {
    return this.network.postMultipart(`${Endpoints.Users}/update-profile-picture/${id}`, params)
  }

  active(params) {
    var id = params.id
    delete (params.id)
    var url = `${Endpoints.Users}/${id}`
    return this.network.postMultipart(url, params)
  }

  update(id, params) {
    return this.network.postMultipart(`${Endpoints.Users}/${id}`, params)
  }

  delete(id) {
    return this.network.delete(`${Endpoints.Users}/${id}`)
  }

  maritalStatus() {
    return this.network.get(Endpoints.MaritalStatus)
  }
  relationship() {
    return this.network.get(Endpoints.Relationship)
  }

  associate(params) {
    return this.network.post(Endpoints.Associate, params)
  }

  createCreditCard(params) {
    return this.network.post(Endpoints.CreditCard, params)
  }

}
