<div class="form-group" [formGroup]="form" *ngIf="!disabled">
  <label *ngIf="label">{{ label }} <span *ngIf="required" class="text-danger">*</span></label>
  <textarea [formControlName]="name" class="form-control form-control-sm" [rows]="rows" [placeholder]="placeholder"
    [ngClass]="ngClass"></textarea>
  <small class="text-danger" *ngIf="messageCondition">
    {{ message }}
  </small>
</div>
<div class="form-group" *ngIf="disabled">
  <label class="text-muted">{{ label }} </label>
  <textarea class="form-control form-control-sm" [value]="value" [disabled]="true" [rows]="rows">{{ value ? value : '' }}</textarea>
</div>
