<nav class="bg-gray-200">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb">
          <li *ngIf="link" class="breadcrumb-item">
            <a routerLink="{{ link }}" class="text-white" routerLinkActive="active">{{ linkName }}</a>
          </li>
          <li *ngIf="sublink" class="breadcrumb-item">
            <a routerLink="{{ sublink }}" class="text-white" routerLinkActive="active">{{ sublinkName }}</a>
          </li>
          <li *ngIf="current" class="breadcrumb-item active" aria-current="page">
            <span style="color:#FFFFFF75">
              {{ current }}
            </span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</nav>
