import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() columns = []
  @Input() selectOptions = []
  @Input() empty: boolean = false
  @Input() more: boolean = false
  @Input() loading: boolean = false
  @Input() hasSearch: boolean = false
  @Input() moveEnabled: boolean = false
  @Input() hasOrder: boolean = false
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() loadMore: EventEmitter<any> = new EventEmitter();
  @Output() selectAction: EventEmitter<any> = new EventEmitter();
  @Output() orderAction: EventEmitter<any> = new EventEmitter();
  @Output() enableAction: EventEmitter<any> = new EventEmitter();

  searchEmitted(e): void {
    this.search.emit(e)
  }

  selectEmitted(e) {
    this.selectAction.emit(e)
  }

  orderEmitted() {
    this.orderAction.emit()
  }

  enableEmitted() {
    this.enableAction.emit()
  }

  loadMoreEmitted(): void {
    this.loadMore.emit()
  }

  constructor() { }

  ngOnInit(): void {
  }

}
