<!-- Card -->
<div class="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">

  <!-- Collapse -->
  <div class="collapse d-md-block" id="sidenavCollapse">
    <div class="card-body">

      <!-- Heading -->
      <!-- <h6 class="font-weight-bold text-uppercase mb-3">
        Account
      </h6> -->

      <!-- List -->
      <ul class="card-list list text-gray-700 mb-6">
        <li class="list-item">
          <a class="list-link text-reset" routerLinkActive="active" href="account-general.html">
            Pedidos
          </a>
        </li>
        <hr>
        <li class="list-item">
          <a class="list-link text-reset" routerLinkActive="active" href="account-security.html">
            Financeiro
          </a>
        </li>
        <hr>
        <li class="list-item">
          <a class="list-link text-reset" routerLinkActive="active" href="account-notifications.html">
            Produtos
          </a>
        </li>
        <hr>
        <li class="list-item">
          <a class="list-link text-reset" routerLinkActive="active" href="account-notifications.html">
            Categorias
          </a>
        </li>
      </ul>

    </div>
  </div>

</div>
