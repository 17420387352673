<div [formGroup]="form">
  <div class="form-group">
    <button class="btn btn-sm btn-primary d-block btn-block"
      [disabled]="disabled">
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span *ngIf="!loading">
        {{ label }}
      </span>
    </button>
  </div>
</div>
