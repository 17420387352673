<app-navbar></app-navbar>
<app-header title="Associar-se ao Clube Vida"></app-header>
<app-section>
  <app-card>
    <div class="progress" style="height: 5px;">
      <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
  </app-card>
  <app-card title="Informações do titular">
    <form [formGroup]="form" (submit)="save()">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-input label="RG" type="text" placeholder="123456789" [form]="form" name="rg"
            [ngClass]="{'is-invalid': form.controls.rg.touched && form.controls.rg.invalid}"
            [messageCondition]="form.controls.rg.touched && form.controls.rg.invalid" message="Digite o RG"
            tip="Digite o RG">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Telefone" type="text" placeholder="(00) 0000-0000" [form]="form" name="landline"
            [mask]="helper.masks.landline"
            [ngClass]="{'is-invalid': form.controls.landline.touched && form.controls.landline.invalid}"
            [messageCondition]="form.controls.landline.touched && form.controls.landline.invalid"
            message="Digite o telefone" tip="Digite o telefone">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-select label="Estado Civil" type="text" name="marital" placeholder="Selecione o estado civil"
            [form]="form" [ngClass]="{'is-invalid': form.controls.marital.touched && form.controls.marital.invalid}"
            [options]="maritals" [optionsId]="true"
            [messageCondition]="form.controls.marital.touched && form.controls.marital.invalid"
            message="Selecione o estado civil">
          </app-select>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Profissão" type="text" placeholder="Administrador" [form]="form" name="profession"
            [ngClass]="{'is-invalid': form.controls.profession.touched && form.controls.profession.invalid}"
            [messageCondition]="form.controls.profession.touched && form.controls.profession.invalid"
            message="Digite a profissão" tip="Digite a profissão">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-select label="Sexo" type="text" name="gender" placeholder="Selecione o sexo" [form]="form"
            [ngClass]="{'is-invalid': form.controls.gender.touched && form.controls.gender.invalid}" [options]="genders"
            [optionsId]="true" [messageCondition]="form.controls.gender.touched && form.controls.gender.invalid"
            message="Selecione o sexo">
          </app-select>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Data de nascimento" type="text" placeholder="DD/MM/AAAA" [form]="form" name="birthdate"
            [mask]="helper.masks.date" [dropSpecialCharacters]="false" (input)="checkAge($event)"
            [ngClass]="{'is-invalid': form.controls.birthdate.touched && form.controls.birthdate.invalid}"
            [messageCondition]="form.controls.birthdate.touched && form.controls.birthdate.invalid"
            message="Titular precisa ser maior de idade" tip="Digite sua data de nascimento">
          </app-input>
        </div>
      </div>
      <div class="row" [formGroup]="addressForm">
        <div class="col-12 col-md-3">
          <app-input label="CEP" type="text" placeholder="00000-000" [form]="addressForm" name="zipcode"
            (input)="cepComplete()"
            [ngClass]="{'is-invalid': addressForm.controls.zipcode.touched && addressForm.controls.zipcode.invalid}"
            [mask]="helper.masks.cep"
            [messageCondition]="addressForm.controls.zipcode.touched && addressForm.controls.zipcode.invalid"
            message="Preencha o CEP" tip="Digite o CEP">
          </app-input>
        </div>
        <div class="col-12 col-md-9">
          <app-input label="Endereço" type="text" placeholder="Rua..." [form]="addressForm" name="street"
            [ngClass]="{'is-invalid': addressForm.controls.street.touched && addressForm.controls.street.invalid}"
            [messageCondition]="addressForm.controls.street.touched && addressForm.controls.street.invalid"
            message="Preencha o endereço" tip="Digite o endereço">
          </app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input label="Número" type="text" placeholder="1000" [form]="addressForm" name="street_number"
            [ngClass]="{'is-invalid': addressForm.controls.street_number.touched && addressForm.controls.street_number.invalid}"
            [messageCondition]="addressForm.controls.street_number.touched && addressForm.controls.street_number.invalid"
            message="Preencha o número" tip="Digite o número">
          </app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input label="Complemento?" type="text" placeholder="Apto, bloco..." tip="Digite o complemento"
            [form]="addressForm" name="complementary">
          </app-input>
        </div>
        <div class="col-12 col-md-4">
          <app-input label="Bairro" type="text" placeholder="Bairro..." [form]="addressForm" name="neighborhood"
            [ngClass]="{'is-invalid': addressForm.controls.neighborhood.touched && addressForm.controls.neighborhood.invalid}"
            [messageCondition]="addressForm.controls.neighborhood.touched && addressForm.controls.neighborhood.invalid"
            message="Preencha o bairro" tip="Digite o bairro">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-input label="Cidade" type="text" placeholder="Cidade..." [form]="addressForm" name="city"
            [ngClass]="{'is-invalid': addressForm.controls.city.touched && addressForm.controls.city.invalid}"
            [messageCondition]="addressForm.controls.city.touched && addressForm.controls.city.invalid"
            message="Preencha a cidade" tip="Digite a cidade">
          </app-input>
        </div>
        <div class="col-12 col-md-6">
          <app-select label="Estado" placeholder="Selecione o estado" [form]="addressForm" name="state" [options]="ufs"
            [optionsId]="true"
            [ngClass]="{'is-invalid': addressForm.controls.state.touched && addressForm.controls.state.invalid}"
            [messageCondition]="addressForm.controls.state.touched && addressForm.controls.state.invalid"
            message="Selecione o estado">
          </app-select>
        </div>
      </div>

      <div class="row align-items-center mt-7">
        <div class="col-12">
          <app-form-button [loading]="loading" label="Continuar" [form]="form" [disabled]="loading || form.invalid">
          </app-form-button>
        </div>
      </div>
    </form>
  </app-card>
</app-section>
