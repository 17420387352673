import { environment } from 'src/environments/environment';
// import { UsersService } from 'src/app/services/users.service';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Endpoints } from '../routes/endpoints';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private network: NetworkService,
    private sessionService: SessionService,
  ) { }

  async login(params) {
    try {
      var r: any = await this.network.post(Endpoints.Login, params)
      if (r.status) {
        if (r.email_confirmed) {
          // var u = await this.network.get(`${Endpoints.Users}/${r.data.id}`, true, r.access_token)
          // let params = { ...u.data, access_token: r.access_token, version: environment.version }
          // this.sessionService.saveToken(r.access_token)
          this.sessionService.saveSession(r)
          return { status: true, data: r }
        } else {
          return { status: false, message: 'O cadastro ainda não foi validado' }
        }
      } else {
        return r
      }
    } catch (c) {
      return { status: false, message: 'Ocorreu um erro ao fazer login', error: c }
    }
  }

  // sendToken(params) {
  //   return this.network.post(Endpoints.PasswordEmail, params)
  // }

  // changePassword(params) {
  //   return this.network.post(Endpoints.PasswordReset, params)
  // }

  getCep(cep) {
    return this.network.makeExternalRequest(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`, null, 'get')
  }
}
