import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helpers/helper.service';
import { PathRoutes } from 'src/app/routes/path-routes';
import { SessionService } from 'src/app/services/session.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  PathRoutes = PathRoutes
  form: FormGroup
  loading = false
  termsOfUse: any
  constructor(
    public helper: HelperService,
    private formBuilder: FormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.setupForm()
  }

  setupForm() {
    this.form = this.formBuilder.group({
      'name': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'cpf': ['', Validators.compose([Validators.required, this.helper.ValidateCPF])],
      'mobile': ['', Validators.required],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      'confirm_password': '',
      'terms': [false],
    })
  }

  async register() {
    this.router.navigate([PathRoutes.Register.Plan])

    try {
      let res: any = await this.userService.save(this.form.getRawValue())
      console.log(res)

    } catch (error) {
      console.log(error)
    }
  }

}
