import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../pages/home/home.component';
import { LoginComponent } from '../pages/login/login.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { RegisterDependentsComponent } from '../pages/register/register-dependents/register-dependents.component';
import { RegisterPlanComponent } from '../pages/register/register-plan/register-plan.component';
import { RegisterUserInfoComponent } from '../pages/register/register-user-info/register-user-info.component';
import { RegisterComponent } from '../pages/register/register.component';
import { AuthService } from '../services/auth.service';
import { RegisterPaymentComponent } from './../pages/register/register-payment/register-payment.component';
import { RegisterSuccessComponent } from './../pages/register/register-success/register-success.component';
import { PathRoutes } from './path-routes';


const routes: Routes = [

  { path: PathRoutes.Login.replace('/', ''), component: LoginComponent },

  { path: '', component: HomeComponent, canActivate: [AuthService] },
  { path: PathRoutes.Home.replace('/', ''), component: HomeComponent, canActivate: [AuthService] },

  { path: PathRoutes.Register.User.replace('/', ''), component: RegisterComponent },
  { path: PathRoutes.Register.Plan.replace('/', ''), component: RegisterPlanComponent, canActivate: [AuthService] },
  { path: PathRoutes.Register.Info.replace('/', ''), component: RegisterUserInfoComponent, canActivate: [AuthService] },
  { path: PathRoutes.Register.Dependents.replace('/', ''), component: RegisterDependentsComponent, canActivate: [AuthService] },
  { path: PathRoutes.Register.Payment.replace('/', ''), component: RegisterPaymentComponent, canActivate: [AuthService] },
  { path: PathRoutes.Register.Success.replace('/', ''), component: RegisterSuccessComponent, canActivate: [AuthService] },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
