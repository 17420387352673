export const PathRoutes = Object({
  Home: '/home',
  Login: '/login',
  Register: {
    User: '/cadastrar',
    Plan: '/cadastrar/plano',
    Info: '/cadastrar/info',
    Dependents: '/cadastrar/dependente',
    Payment: '/cadastrar/pagamento',
    Success: '/cadastrar/sucesso'
  },
});



